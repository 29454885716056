import React, { FC } from 'react'
import { RiCustomerService2Line } from 'react-icons/ri'
import { Button, useBreakpointValue } from '@chakra-ui/react'
import { Link } from '@chakra-ui/next-js'
import appConfig from '#/src/appConfig'
import { useGoogleAnalytics } from 'ui/lib/third-party'
import { GrandIconButton } from 'ui'

interface IGrandContactButtonProps {
  btnText?: string
  buttonProps?: any
  menu?: boolean
}

const GrandContactButton: FC<IGrandContactButtonProps> = ({ btnText, menu, buttonProps }) => {
  const GA = useGoogleAnalytics()

  const handleClick = () => {
    GA.selectContent({
      content_type: 'contact_form',
      item_id: 'contact_form'
    })
  }

  const isDesktopWidth = useBreakpointValue({
    base: false,
    md: true
  })

  if (menu) {
    // @ts-ignore
    return (
      <Link href={appConfig.staticPagesUrls.contactUs}>
        <Button
          {...(!isDesktopWidth
            ? {
                display: 'none'
              }
            : {})}
          bg="brand.lemon"
          border="none"
          borderRadius="3xl"
          color="text.default"
          fontSize="sm"
          onClick={handleClick}
          {...buttonProps}>
          Kontakta oss
        </Button>

        <GrandIconButton
          {...(isDesktopWidth
            ? {
                display: 'none'
              }
            : {})}
          alignItems="center"
          aria-label="Kontakta oss"
          bg="none"
          color="text.default"
          icon={<RiCustomerService2Line />}
          justifyContent="center"
          onClick={handleClick}
          shadow="none"
          size="sm"
        />
      </Link>
    )
  }

  return (
    <Link href={appConfig.staticPagesUrls.contactUs}>
      <Button bg="transparent" color="primary" onClick={handleClick} px={2} {...buttonProps}>
        {btnText ? btnText : 'Kontakta oss'}
      </Button>
    </Link>
  )
}

export default GrandContactButton
