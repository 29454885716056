import { useSelector } from 'shared-redux'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from '@chakra-ui/next-js'
import { Box, Flex, FlexProps } from '@chakra-ui/react'
import { GrandText } from 'ui'
import { selectedMenuCategoryColors } from '#/src/custom/redux-toolkit/categories/customCategorySlice'
import { CategoryDTO } from 'ecosystem'
import { customTheme } from '#/src/custom/styles/theme'

type NavListItemProps = {
  item: Partial<CategoryDTO>
  isLink?: boolean
  isSelected?: boolean
  isReversedLinkStyle?: boolean
} & FlexProps

export const NavListItem = ({
  item,
  isLink,
  isSelected,
  isReversedLinkStyle,
  ...props
}: NavListItemProps) => {
  const colors = useSelector(selectedMenuCategoryColors)
  const bgActive = colors?.itemBgActive || 'gray.300'
  const bgHover = colors?.itemBg || 'gray.200'

  if (isLink || !item.subCategories?.length) {
    return (
      <Flex fontSize="sm" py={1} {...props}>
        <Link
          _hover={{
            textDecoration: isReversedLinkStyle ? 'none' : 'underline'
          }}
          href={`/categories/${item.slug}`}
          textDecoration={isReversedLinkStyle ? 'underline' : 'none'}>
          {item.name}
        </Link>
      </Flex>
    )
  }

  return (
    <Box w="100%">
      <Flex
        _hover={{
          bg: isSelected ? bgActive : bgHover,
          cursor: isSelected ? 'default' : 'pointer'
        }}
        alignItems="center"
        bg={isSelected ? bgActive : ''}
        borderRadius="2xl"
        fontSize="sm"
        justifyContent="space-between"
        mx={-3}
        px={3}
        py={1}
        w={`calc(100% + ${customTheme.space[6]})`}
        {...props}>
        <GrandText>{item.name}</GrandText>

        <Box fontSize="lg">
          <MdKeyboardArrowRight />
        </Box>
      </Flex>
    </Box>
  )
}
