import { PropsWithChildren } from 'react'
import { StackProps, VStack } from '@chakra-ui/react'

export const MobileMenuGroup = ({ children, ...props }: PropsWithChildren<StackProps>) => {
  return (
    <VStack
      _after={{
        content: '""',
        position: 'absolute',
        width: 'calc(100% - 14px)',
        bottom: '0',
        left: '7px',
        height: '1px',
        background: '#57678680'
      }}
      _last={{
        '&:after': {
          display: 'none'
        }
      }}
      alignItems="flex-start"
      className="mobile-menu-group"
      position="relative"
      spacing="0"
      w="100%"
      {...props}>
      {children}
    </VStack>
  )
}
