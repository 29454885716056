import { FC } from 'react'
import { Contact, ContactPure, IContactProps } from 'storefront-modules/contact'
import { useModal } from '#/src/custom/controllers'

export interface CustomContactProps extends Omit<IContactProps, 'apiPath' | 'sectionId'> {
  isPureComponent?: boolean
}

const CustomContact: FC<CustomContactProps> = ({
  containerProps,
  formContainerProps,
  isPureComponent, // without wrapper <GrandSection> and etc
  onSuccess,
  submitButtonProps,
  ...props
}) => {
  const { openModal } = useModal()

  const onSuccessCb = () => {
    openModal({
      title: 'Request sent',
      componentId: 'confirmation',
      size: 'md'
    })
  }

  const commonProps = {
    apiPath: '/api/contact/send-contact',
    submitButtonProps: {
      borderRadius: '3xl',
      ...submitButtonProps
    },
    onSuccess: onSuccess || onSuccessCb,
    ...props
  }

  return isPureComponent ? (
    <ContactPure {...commonProps} {...formContainerProps} />
  ) : (
    <Contact sectionId="contact" {...{ containerProps, formContainerProps }} {...commonProps} />
  )
}

export default CustomContact
