import { Box, HStack } from '@chakra-ui/react'
import { BiSearch } from 'react-icons/bi'
import { GrandText } from 'ui'

type MobileMenuItemSearchProps = {
  onClick: () => void
}

export const MobileMenuItemSearch = ({ onClick }: MobileMenuItemSearchProps) => {
  return (
    <HStack
      _hover={{
        '& .mobile-menu-search-item__text': {
          textDecoration: 'underline'
        }
      }}
      alignItems="center"
      className="mobile-menu-search-item"
      cursor="pointer"
      fontSize="md"
      onClick={onClick}
      px={6}
      py={4}
      userSelect="none">
      <Box fontSize="xl">
        <BiSearch />
      </Box>

      <GrandText className="mobile-menu-search-item__text">Sök efter</GrandText>
    </HStack>
  )
}
