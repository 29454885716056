import React, { FC } from 'react'
import { BorderProps, StackProps, TextProps, VStack } from '@chakra-ui/react'
import { GrandDivider, GrandText } from 'ui'

interface IHoverableTextUnderlineProps extends StackProps {
  isHovered: boolean
  underlineColor?: BorderProps['borderColor']
  tagOfText?: TextProps['as']
}

const HoverableTextUnderline: FC<IHoverableTextUnderlineProps> = ({
  isHovered,
  underlineColor = 'primary',
  tagOfText,
  children,
  ...props
}) => {
  return (
    <VStack cursor="pointer" fontSize="sm" spacing={0} {...props}>
      <GrandText as={tagOfText} cursor="inherit" isTruncated>
        {children}
      </GrandText>
      <GrandDivider
        borderColor={isHovered ? underlineColor : 'transparent'}
        borderWidth={1}
        opacity={1}
        width={isHovered ? '100%' : 0}
      />
    </VStack>
  )
}

export default HoverableTextUnderline
