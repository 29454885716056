'use client'
import { FC, ReactNode, useMemo } from 'react'
import { logo } from '#/src/custom/constants/layout'
import { Link } from '@chakra-ui/next-js'
import { useModal } from '#/src/custom/controllers'
import { CategoryDTO, FooterItem, RequiredPageProps } from 'ecosystem'
import appConfig from '#/src/appConfig'
import { MenuItem, TopNavSpacer } from 'ui/storefront'
import { ImageBox } from 'ui'
import { Button, useBreakpointValue } from '@chakra-ui/react'
import { PageLayoutContainer } from 'storefront-modules/page'
import { MainMenuItem } from './types'
import { filterCategories } from 'shared-utils'
import CustomContactButton from '#/src/custom/components/CustomContactButton'
import { CustomSearchButton } from '#/src/custom/components/CustomSearchButton'
import TopNav from './custom-layout-components/TopNav/TopNav'
import Footer from '#/src/custom/layout/custom-layout-components/Footer/Footer'
import { useSelector } from 'shared-redux'
import { selectIsWindowOnTop } from 'shared-redux/state'

export type CustomLayoutProps = RequiredPageProps & {
  children: ReactNode | ReactNode[]
}

const CustomLayout: FC<CustomLayoutProps> = ({
  children,
  footerWidgets,
  staticPages,
  categories
}) => {
  const { isHidden: isModalHidden, size: modalSize } = useModal()
  const isWindowOnTop = useSelector(selectIsWindowOnTop)

  const categoryItems = useMemo<MainMenuItem<CategoryDTO>[]>(() => {
    const convertCategories = (categoriesList?: CategoryDTO[]) => {
      return filterCategories(categoriesList || []).map((item) => {
        const path = `${appConfig.paths.categoriesUrlPath}/${item.slug}`

        const menuItem: MainMenuItem = {
          id: item.slug,
          text: item.name,
          path,
          type: 'store-front-category',
          originalData: item,
          ...(!!item.subCategories?.length
            ? { options: convertCategories(item.subCategories) }
            : {})
        }
        return menuItem
      })
    }
    return convertCategories(categories)
  }, [categories])

  const menuItems: {
    categories: MenuItem[]
    other: MenuItem[]
  } = useMemo(() => {
    return {
      categories: [...categoryItems],
      other: [
        ...(staticPages
          ? staticPages.map((sp) => ({
              id: 'other-' + sp.id,
              text: sp.name,
              path: sp.slug ? `/${sp.slug}` : '/'
            }))
          : []),
        ...appConfig.staticLinks.map(({ label, ...s }) => ({
          ...s,
          text: label
        }))
      ]
    }
  }, [categoryItems, staticPages])

  const panelItems = appConfig.topNavPanel?.items

  const footerItems: FooterItem[] = footerWidgets.map(({ widget }) => widget)

  // noinspection HtmlUnknownTarget
  const navbarActions = [
    {
      id: 0,
      component: (
        <Link href={appConfig.staticPagesUrls.bookingService}>
          <Button
            bg="brand.blue"
            border="none"
            borderRadius="3xl"
            color="text.default"
            fontSize="sm"
            px={6}
            py={0.5}
            shadow="sm"
            size="md">
            Boka våra tjänster
          </Button>
        </Link>
      ),
      showsOnMobile: false
    },
    {
      id: 1,
      component: <CustomContactButton menu />,
      showsOnMobile: true
    },
    {
      id: 2,
      component: <CustomSearchButton />,
      showsOnMobile: false
    }
  ]

  const logoHeaderSrc = useBreakpointValue({ base: logo.header.mobile, lg: logo.header.desktop })
  const logoFooterSrc = useBreakpointValue({ base: logo.footer.mobile, lg: logo.footer.desktop })

  const logoComponent = (
    <ImageBox
      h={{
        base: '27px',
        lg: '35px'
      }}
      imageProps={{
        title: appConfig.site.title,
        alt: appConfig.site.title,
        src: logoHeaderSrc,
        style: {
          objectFit: 'contain'
        },
        quality: 100,
        priority: true,
        sizes: '10em'
      }}
      w={{
        base: '214px',
        lg: '280px'
      }}
    />
  )

  const footerLogoComponent = (
    <ImageBox
      h="35px"
      imageProps={{
        title: appConfig.site.title,
        alt: appConfig.site.title,
        src: logoFooterSrc,
        style: {
          objectFit: 'contain'
        },
        quality: 100,
        priority: true
      }}
      w="280px"
    />
  )

  return (
    <PageLayoutContainer
      pageContextProps={{
        categories: categories || [],
        footerWidgets,
        staticPages
      }}
      position="relative">
      <TopNav
        logo={logoComponent}
        zIndex={!isModalHidden && modalSize === 'full' ? 0 : 9000}
        {...{ panelItems, menuItems, navbarActions }}
      />
      <TopNavSpacer
        minH={
          isWindowOnTop
            ? appConfig.topNavHeight.spacer
            : {
                base: '7vh',
                lg: '16vh'
              }
        }
      />
      {children}
      <Footer logo={footerLogoComponent} {...{ footerItems }} />
    </PageLayoutContainer>
  )
}

export default CustomLayout
