import React, { FC, ReactElement } from 'react'
import { Box, Flex, Grid, GridItem, HStack, useBreakpointValue } from '@chakra-ui/react'
import appConfig from '#/src/appConfig'
import { FooterBottomItem, FooterBottomItemProps } from './FooterBottomItem'
import { FooterItem } from 'ecosystem'
import { GrandImage, GrandNextLink, GrandSection } from 'ui'
import FooterColumn from './FooterColumn'
import Link from 'next/link'
import granditudeLogo from '#/src/images/granditude_logo.png'

interface IFooter {
  logo: ReactElement
  footerItems: FooterItem[]
}

const bottomFooterItems: FooterBottomItemProps[] = []

const Footer: FC<IFooter> = ({ logo, footerItems }) => {
  const templateColumns = useBreakpointValue(
    {
      base: '1fr',
      md: `repeat(${Math.ceil(footerItems.length / 3)}, 1fr)`,
      lg: `repeat(${Math.ceil(footerItems.length / 2)}, 1fr)`,
      xl: `repeat(${footerItems.length}, 1fr)`
    },
    { ssr: true }
  )

  return (
    <GrandSection
      bg="text.default"
      color="text.light"
      contentContainerProps={{
        spacing: {
          base: 16,
          lg: 24
        },
        py: {
          base: '10',
          md: '10',
          lg: '16'
        }
      }}
      position="relative"
      py="0"
      sectionId="footer"
      sx={{
        '.grand-section__divider': {
          display: 'none'
        }
      }}>
      <Flex w="100%">
        <GrandNextLink aria-label={appConfig.site.title} href="/">
          {logo}
        </GrandNextLink>
      </Flex>

      <Grid columnGap={4} rowGap={12} templateColumns={templateColumns}>
        {footerItems.map((item, index) => (
          <GridItem key={index}>
            <FooterColumn {...{ item }} />
          </GridItem>
        ))}
      </Grid>

      <Flex
        borderTop="1px solid"
        borderTopColor="text.darkMild"
        justifyContent="space-between"
        pt={3}
        w="100%">
        {!!bottomFooterItems.length && (
          <HStack
            spacing={{
              base: 6,
              md: 8,
              lg: 10,
              xl: 12
            }}>
            {bottomFooterItems.map((item, index) => (
              <FooterBottomItem key={index} {...item} />
            ))}
          </HStack>
        )}

        <HStack alignItems="center" gap={4}>
          <Box opacity="0.7" fontSize={['xs', 'sm']}>
            Powered by
          </Box>
          <Link href="https://granditude.com" target="_blank">
            <GrandImage maxW="100px" src={granditudeLogo} alt="Granditude" sizes="100px" />
          </Link>
        </HStack>

        <FooterBottomItem text={`© ${new Date().getFullYear()}`} />
      </Flex>
    </GrandSection>
  )
}

export default Footer
