import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'shared-redux'
import { usePathname } from 'next/navigation'
import { BoxProps, Flex, HStack } from '@chakra-ui/react'
import appConfig from '#/src/appConfig'

import TopNavMenuItemComponent, { TopNavMenuItem } from './TopNavMenuItem'
import { ContentContainer, MAX_CONTENT_WIDTH } from 'ui'
import {
  selectedMenuCategoryId,
  setSelectedMenuCategory,
  setSelectedMenuCategoryColors
} from '#/src/custom/redux-toolkit/categories/customCategorySlice'
import { CategoryDTO } from 'ecosystem'
import { menuColors } from '#/src/custom/constants/layout'
import { CustomSlideOutPayloadProps, useSlideOut } from '#/src/custom/controllers'
import { MenuCategoryColors } from '#/src/custom/redux-toolkit/categories/types'

type TopNavMenuProps = {
  categoriesItems: TopNavMenuItem<CategoryDTO>[]
  otherItems: TopNavMenuItem[]
} & BoxProps

const TopNavMenu = ({ categoriesItems, otherItems }: TopNavMenuProps) => {
  const pathname = usePathname()
  const dispatch = useDispatch()
  const { isHidden, closeSlideOut, openSlideOut, componentId, overrideComponent } = useSlideOut()
  const menuCategoryId = useSelector(selectedMenuCategoryId)
  const isMenuDesktopOpen = !isHidden && componentId === 'categories'

  const handleCategoriesToggle = useCallback(
    (subCategories: CategoryDTO[], colors: MenuCategoryColors) => {
      if (!subCategories.length) {
        return closeSlideOut()
      }

      const slideOutProps: CustomSlideOutPayloadProps<void> = {
        title: 'Categories',
        componentId: 'categories' as const,
        shouldUnmountComponentAfterClose: true,
        drawerProps: {
          sx: {
            bg: 'transparent',
            px: '0',
            boxShadow: 'none',
            '.slide-out__content-container': {
              py: '0',
              boxShadow: 'md',
              maxWidth: MAX_CONTENT_WIDTH,
              margin: 'auto',
              bg: 'background.default'
            },
            '.slide-out__content-body': {
              px: '0'
            }
          }
        }
      }

      if (!isHidden && componentId !== 'categories') {
        overrideComponent(slideOutProps)
      } else if (isHidden) {
        openSlideOut(slideOutProps)
      }

      dispatch(setSelectedMenuCategoryColors(colors))
    },
    [closeSlideOut, componentId, dispatch, isHidden, openSlideOut, overrideComponent]
  )

  const fullCategoryItems = useMemo<TopNavMenuItem<CategoryDTO>[]>(() => {
    return categoriesItems.map((item, index) => {
      const isActive = isMenuDesktopOpen && menuCategoryId === item.id
      const colors = menuColors[index] || menuColors[0]
      const subCategories = item.originalData?.subCategories || []
      const expandable = !!item.originalData?.subCategories?.length

      const menuItem: TopNavMenuItem = {
        ...item,
        expanded: isActive,
        expandable,
        expandedHighlightBg: colors.topNavItem,
        onHoverEnter: () => {
          if (!isActive) {
            handleCategoriesToggle(subCategories, colors)
            dispatch(setSelectedMenuCategory(item.originalData || null))
          }
        }
      }

      return menuItem
    })
  }, [categoriesItems, dispatch, handleCategoriesToggle, isMenuDesktopOpen, menuCategoryId])

  const fullOtherItems = useMemo<TopNavMenuItem[]>(() => {
    return otherItems.map((item) => ({
      ...item,
      onHoverEnter: () => isMenuDesktopOpen && closeSlideOut()
    }))
  }, [otherItems, isMenuDesktopOpen, closeSlideOut])

  return (
    <Flex
      alignItems="stretch"
      background="linear-gradient(90.11deg, #396761 21.13%, #579586 96.71%)"
      className="top-nav-menu"
      display={{
        base: 'none',
        lg: 'flex'
      }}
      h={appConfig.topNavHeight.menu}
      position="relative"
      w="full"
      zIndex="2">
      <ContentContainer
        justifyContent="space-between"
        px={{
          base: 4,
          md: 6
        }}
        py={{
          base: 0,
          md: 0
        }}>
        <HStack spacing={4}>
          {fullCategoryItems.map((item, index) => (
            <TopNavMenuItemComponent
              key={index}
              {...item}
              isCurrent={!!pathname?.includes(item?.path)}
              text={item.text}
            />
          ))}
        </HStack>

        <HStack spacing={4}>
          {fullOtherItems.map((item, index) => (
            <TopNavMenuItemComponent
              key={index}
              {...item}
              isCurrent={pathname === item?.path}
              text={item.text}
            />
          ))}
        </HStack>
      </ContentContainer>
    </Flex>
  )
}

export default TopNavMenu
