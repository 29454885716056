import {
  defineStyleConfig,
  extendTheme,
  ThemeConfig,
  withDefaultColorScheme
} from '@chakra-ui/react'
import { GlobalProps } from '@emotion/react'
import merge from 'lodash/merge'
import appConfig from '#/src/appConfig'
import { fonts } from './fonts'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false
}

const breakpoints = {
  xs: '30em',
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em'
}

const Checkbox = defineStyleConfig({
  defaultProps: {
    colorScheme: 'none'
  },
  baseStyle: {
    control: {
      bg: 'background.default',
      _checked: {
        bg: 'primary'
      },
      _focus: {
        shadow: 'none'
      }
    }
  }
})

const Popover = defineStyleConfig({
  variants: {
    responsive: {
      popper: {
        maxWidth: {
          base: 'unset',
          md: '500px'
        }
      },
      // @ts-expect-error -- TODO Chakra marks it as incompatible. Check if its working
      content: {
        width: {
          base: '100vw',
          md: '500px'
        }
      }
    }
  }
})

const Switch = defineStyleConfig({
  defaultProps: {
    colorScheme: 'gray'
  }
})

const Link = defineStyleConfig({
  baseStyle: {
    cursor: 'pointer'
  }
})

const Heading = defineStyleConfig({
  baseStyle: {
    fontFamily: 'heading'
  }
})

const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: 'md',
    backgroundColor: 'primary',
    color: 'text.light',
    fontWeight: 'medium',
    minW: 140,
    outlineOffset: 0,
    transition: 'all 0.35s cubic-bezier(.08,.52,.52,1)'
  },
  sizes: {
    md: {
      minW: 140
    }
  },
  variants: {
    plain: {
      h: 'auto',
      w: 'auto',
      minW: 'auto',
      fontSize: 'inherit',
      color: 'inherit',
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
      bg: 'transparent',
      p: 0
    }
  }
})

const CustomFooter = defineStyleConfig({
  baseStyle: (props) => {
    const footerStyles = props.theme?.components?.CustomFooter || {}
    return {
      bg: 'background.surface',
      p: {
        base: 4,
        md: 10
      },
      ...footerStyles
    }
  }
})

export const defaultTheme = extendTheme(
  withDefaultColorScheme({
    colorScheme: 'customColorScheme'
  }),
  {
    components: {
      Checkbox,
      Popover,
      Switch,
      Link,
      Button,
      Heading,
      CustomFooter
    },
    config,
    breakpoints,
    styles: {
      global: (_props: GlobalProps) => ({
        html: {},
        body: {
          bg: 'background.surface'
        },
        '#__next': {},
        ':root': {
          // Add root styles or variables here. i.e.
          // '--chakra-colors-name-of-the-color': mode(
          //     '#FDFDFF',
          //     brand[1000]
          // )( props ),
        }
      })
    },
    colors: {
      primary: '#57BDAB', //default accent
      secondary: '#cd0f32', //focus accent
      // Value should be a CSS hex color, e.g. "#FF9900". RegExp: ^#[A-Fa-f0-9]{6}$
      klarna: '#57BDAB',
      background: {
        default: '#FFF',
        surface: '#f4f4f4',
        mildDark: 'rgb(1,50,75)',
        dark: 'rgb(0,25,38)',
        darkest: 'rgb(0,19,28)',
        translucent: 'rgba(244,244,244, 0.90)',
        overlayHalf: 'rgba(0,0,0, 0.25)',
        overlay: 'rgba(0,0,0, 0.5)',
        primary: 'rgba(146,176,156, .1)', //transparentized primary
        secondary: 'rgba(205,15,50, .1)', //transparentized secondary
        lightgreen: '#E6FCF8',
        lightBlue: '#ECEFF4'
      },
      text: {
        default: '#2F3746',
        disabled: '#f4f4f4',
        // used as mild with light backgrounds
        mild: 'rgba(28,38,51,0.5)',
        light: 'white',
        lightMild: 'rgba(255,255,255,0.5)',
        darkGrey: '#636363',
        // used as mild with dark backgrounds
        darkMild: 'rgba(254,254,254,0.3)',
        colored: '#315d91'
      },
      status: {
        success: 'rgba(29,195,149, 1)',
        warning: '#eed892',
        warning2: '#f5e6d6',
        error: '#f35f5f'
      },
      brand: {
        pink: '#ff6384ff',
        plum: '#bb6bd9',
        plumDarker: '#9b42a0',
        plumLighter: '#cf97e4',
        teal: '#26aebd',
        tealDarker: '#007b8b',
        tealLighter: '#67c6d1',
        yellow: '#feca57',
        orange: '#f7b092',
        violet: '#7448ff',
        violetDarker: '#6c42f0',
        violetLighter: '#9d7fff',
        blue: '#81B9ED',
        blueDarker: '#315d91',
        blueLighter: '#62bbef',
        lightYellow: '#F0E1CB',
        lightYellow2: '#FFF0DB',
        // used as badge backgrounds
        plumExtraLight: 'rgba(207,151,228,0.20)',
        tealExtraLight: 'rgba(62,91,98,0.2)',
        blueExtraLight: 'rgba(98,187,239,0.20)',
        darkExtraLight: 'rgba(0,25,38,0.20)',
        darkGrey: '#222222',
        seaGreen: '#43887F',
        lemon: '#FFE978'
      }
    },
    textStyles: {
      slogan: {
        fontFamily: 'var(--chakra-fonts-slogan)'
      }
    },
    textDecoration: {
      underline: {
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
        textDecorationColor: 'color.highlight'
      }
    },
    shadows: {
      //the 'outline' shadow preset is replaced with the default accent color: secondary
      outline: '0 0 0 2px #cd0f32',
      //outline style shadows for each preset,
      primary: '0 0 0 2px #92b09c',
      secondary: '0 0 0 2px #cd0f32',
      //blur style shadows for each preset,
      primaryBlur: '0 0 4px 2px #92b09c',
      secondaryBlur: '0 0 4px 2px #cd0f32'
    },
    fonts
  }
)
export const customTheme = merge(defaultTheme, appConfig.theme)
