import { Flex, FlexProps } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import appConfig from '#/src/appConfig'
import { GrandIconButton } from 'ui'

export const MobileMenuClose = (props: FlexProps) => {
  const barMobileHeight = (appConfig.topNavHeight.bar as { base: string })?.base || '7vh'

  return (
    <Flex
      alignItems="center"
      background="text.default"
      className="mobile-menu-close"
      justifyContent="center"
      left="0"
      position="absolute"
      top="0"
      transform="translateY(-100%)"
      {...props}>
      <GrandIconButton
        aria-label="Close menu"
        borderRadius="none"
        color="#fff"
        height={barMobileHeight}
        icon={<MdClose />}
        shadow="none"
        variant="ghost"
        width={barMobileHeight}
      />
    </Flex>
  )
}
