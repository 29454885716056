import React, { ReactElement } from 'react'
import { MdClose, MdOutlineMenu } from 'react-icons/md'
import { Box, BoxProps, Flex, HStack } from '@chakra-ui/react'
import appConfig from '#/src/appConfig'
import { TopNavBarAction } from './types'
import { CategoryDTO } from 'ecosystem'
import { MainMenuItem } from '#/src/custom/layout/types'
import { useSlideOut } from '#/src/custom/controllers'
import { SlideOutMobileMenuProps } from '#/src/custom/controllers/slideouts/SlideOutMobileMenu'
import { ContentContainer, GrandIconButton, GrandNextLink, HoverableComponent } from 'ui'

type TopNavBarProps = {
  categoriesMenuItems: MainMenuItem<CategoryDTO>[]
  otherMenuItems: MainMenuItem[]
  logo: ReactElement
  actions: TopNavBarAction[]
} & BoxProps

const TopNavBar = ({
  categoriesMenuItems,
  otherMenuItems,
  logo,
  actions,
  ...props
}: TopNavBarProps) => {
  const { openSlideOut, isHidden, closeSlideOut, componentId } = useSlideOut()

  const handleHoverTopNav = (isInSide: boolean) => {
    if (isInSide && componentId === 'categories') {
      closeSlideOut()
    }
  }

  const handleMenuToggle = () => {
    if (isHidden) {
      openSlideOut<SlideOutMobileMenuProps>({
        title: '',
        componentId: 'mobileMenu',
        shouldUnmountComponentAfterClose: true,
        extraArgs: {
          itemsGroups: [categoriesMenuItems, otherMenuItems]
        },
        overlayProps: {
          zIndex: 9001
        },
        drawerProps: {
          sx: {
            zIndex: 9002,
            height: '100%',
            bg: 'transparent',
            px: '0',
            boxShadow: 'none',
            '.slide-out__content-container': {
              py: '0',
              width: '90%',
              height: '100%'
            },
            '.slide-out__content-body': {
              px: '0',
              height: '100%',
              overflowY: 'visible',
              position: 'relative'
            }
          }
        }
      })
    } else {
      closeSlideOut()
    }
  }

  return (
    <Box
      bg={{
        base: 'white',
        lg: 'brand.seaGreen'
      }}
      boxShadow="md"
      className="top-nav-bar"
      h={appConfig.topNavHeight.bar}
      position="relative"
      transition="all 0.25s ease"
      w="full"
      zIndex="2"
      {...props}>
      <HoverableComponent
        alignItems="center"
        cursor="default"
        display="flex"
        onHover={handleHoverTopNav}>
        <ContentContainer
          px={{
            base: 4,
            lg: 6
          }}
          py={{
            base: 0,
            md: 0
          }}>
          <HStack align="center" flex={1} w="full">
            <Flex
              alignItems="center"
              display={{
                base: 'flex',
                lg: 'none'
              }}
              flex={1}>
              <GrandIconButton
                aria-label="open-menu"
                bg="none"
                color="text.default"
                icon={isHidden ? <MdOutlineMenu /> : <MdClose />}
                onClick={handleMenuToggle}
                shadow="none"
                size="sm"
              />
            </Flex>

            <Flex
              cursor="default"
              flex={2}
              justifyContent={{
                base: 'center',
                lg: 'flex-start'
              }}>
              <GrandNextLink aria-label={appConfig.site.title} cursor="pointer" href="/">
                {logo}
              </GrandNextLink>
            </Flex>

            <HStack
              align="center"
              className="top-nav-bar-actions"
              flex={1}
              justify="flex-end"
              spacing={{ base: 0, md: 6 }}>
              {actions.map(({ component, id, showsOnMobile }) => (
                <Box
                  display={{
                    base: showsOnMobile ? 'initial' : 'none',
                    lg: 'initial'
                  }}
                  key={id}>
                  {component}
                </Box>
              ))}
            </HStack>
          </HStack>
        </ContentContainer>
      </HoverableComponent>
    </Box>
  )
}

export default TopNavBar
