import logoMobile from '#/src/images/stenlunds_prof_logo_black_center.png'
import logoDesktop from '#/src/images/stenlunds_prof_logo_white_left.png'
import { MenuCategoryColors } from '#/src/custom/redux-toolkit/categories/types'

export const menuColors: MenuCategoryColors[] = [
  {
    topNavItem: '#576786',
    columnBg: '#e7ebf1',
    itemBg: '#5767861F',
    itemBgActive: '#5767864d'
  },
  {
    topNavItem: '#4E867F',
    columnBg: '#f5f8f8',
    itemBg: '#4E867F33',
    itemBgActive: '#4e867f47'
  }
]

export const logo = {
  header: {
    mobile: process.env.NEXT_PUBLIC_LOGO_MOBILE || logoMobile.src || '',
    desktop: process.env.NEXT_PUBLIC_LOGO_DESKTOP || logoDesktop.src || ''
  },
  footer: {
    mobile: process.env.NEXT_PUBLIC_LOGO_FOOTER_MOBILE || logoDesktop.src || '',
    desktop: process.env.NEXT_PUBLIC_LOGO_FOOTER_DESKTOP || logoDesktop.src || ''
  }
} as const
