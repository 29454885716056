import { Box, Flex, HStack } from '@chakra-ui/react'
import { MdArrowBackIos } from 'react-icons/md'
import { BiSearch } from 'react-icons/bi'
import { GrandIconButton, GrandText } from 'ui'

type MobileMenuTopPanelProps = {
  onClickBack: () => void
  onClickSearch: () => void
}

export const MobileMenuTopPanel = ({ onClickBack, onClickSearch }: MobileMenuTopPanelProps) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" userSelect="none" w="100%">
      <HStack
        alignItems="center"
        cursor="pointer"
        fontSize="md"
        onClick={onClickBack}
        px={6}
        py={4}>
        <Box>
          <MdArrowBackIos />
        </Box>

        <GrandText>Tillbaka</GrandText>
      </HStack>

      <GrandIconButton
        aria-label="search"
        icon={<BiSearch />}
        onClick={onClickSearch}
        px={6}
        py={4}
        shadow="none"
        variant="ghost"
      />
    </Flex>
  )
}
