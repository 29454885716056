import { MdArrowForwardIos } from 'react-icons/md'
import { Link } from '@chakra-ui/next-js'
import { Box, Flex, FlexProps } from '@chakra-ui/react'
import { MainMenuItem } from '#/src/custom/layout/types'
import { GrandText } from 'ui'

type NavListItemProps = {
  item: MainMenuItem
  isLink?: boolean
  reverseLinkStyle?: boolean
} & FlexProps

export const MobileMenuListItem = ({
  item,
  isLink,
  reverseLinkStyle,
  ...props
}: NavListItemProps) => {
  const commonStyles = {
    py: 4,
    px: 6,
    fontSize: 'md'
  }

  if (isLink || !item.options?.length) {
    return (
      <Flex {...props}>
        <Link
          {...commonStyles}
          _hover={{
            textDecoration: reverseLinkStyle ? 'underline' : 'none'
          }}
          href={item.path}
          textDecoration={reverseLinkStyle ? 'none' : 'underline'}
          userSelect="none">
          {item.text}
        </Link>
      </Flex>
    )
  }

  return (
    <Box w="100%">
      <Flex
        {...commonStyles}
        _hover={{
          bg: '#5767861F'
        }}
        alignItems="center"
        cursor="pointer"
        justifyContent="space-between"
        userSelect="none"
        w="100%"
        {...props}>
        <GrandText>{item.text}</GrandText>

        <Box fontSize="md">
          <MdArrowForwardIos />
        </Box>
      </Flex>
    </Box>
  )
}
