import { Fragment, ReactNode, useMemo, useState } from 'react'
import { Box, useUpdateEffect, VStack } from '@chakra-ui/react'
import { MobileMenuListItem } from './MobileMenuListItem'
import { MobileMenuGroup } from './MobileMenuGroup'
import { MobileMenuTopPanel } from './MobileMenuTopPanel'
import { MobileMenuClose } from './MobileMenuClose'
import { MobileMenuSearchScreen } from './MobileMenuSearchScreen'
import { MobileMenuItemSearch } from './MobileMenuItemSearch'
import appConfig from '#/src/appConfig'
import { MainMenuItem } from '#/src/custom/layout/types'
import { useSlideOut } from '#/src/custom/controllers'
import { HoverableComponent } from 'ui'
import { isNullable } from 'shared-utils'

type MobileMenuItem = MainMenuItem & {
  component?: ReactNode
}

export type SlideOutMobileMenuProps = {
  itemsGroups: MainMenuItem[][]
}

const SlideOutMobileMenu = ({ itemsGroups }: SlideOutMobileMenuProps) => {
  const { closeSlideOut } = useSlideOut()
  const [isHoverMenu, setIsHoverMenu] = useState(false)
  const [isSearchScreenActive, setIsSearchScreenActive] = useState(false)
  const [subMenus, setSubMenus] = useState<MainMenuItem[]>([])

  const groups = useMemo<MobileMenuItem[][]>(() => {
    return [
      ...itemsGroups,
      [
        {
          id: 'contact-us',
          text: 'Kontakta oss',
          path: appConfig.staticPagesUrls.contactUs
        },
        {
          id: 'boka-service',
          text: 'Boka våra tjänster',
          path: appConfig.staticPagesUrls.bookingService
        },
        {
          id: 'search-link',
          text: '',
          path: '',
          component: <MobileMenuItemSearch onClick={() => setIsSearchScreenActive(true)} />
        }
      ]
    ]
  }, [itemsGroups])

  const addSubMenu = (menuItem: MainMenuItem) => {
    if (menuItem.options?.length) {
      setSubMenus([...subMenus, menuItem])
    }
  }

  const goPrevMenu = () => {
    setSubMenus(subMenus.slice(0, subMenus.length - 1))
  }

  const currentSubMenu = subMenus[subMenus.length - 1]

  useUpdateEffect(() => {
    if (!isHoverMenu) {
      closeSlideOut()
    }
  }, [isHoverMenu])

  return (
    <HoverableComponent
      className="mobile-menu"
      cursor="default"
      left="0"
      onHover={setIsHoverMenu}
      position="absolute"
      top="0">
      <MobileMenuClose onClick={closeSlideOut} zIndex="1" />

      <Box
        bg="background.default"
        className="mobile-menu-body"
        height="100%"
        overflowY="auto"
        position="relative"
        zIndex="2">
        {!currentSubMenu && !isSearchScreenActive && (
          <VStack alignItems="stretch" className="mobile-menu-main" w="100%">
            {groups.map((group, groupIndex) => (
              <MobileMenuGroup key={groupIndex}>
                {group.map((item) =>
                  isNullable(item.component) ? (
                    <MobileMenuListItem
                      item={item}
                      key={item.id}
                      onClick={() => addSubMenu(item)}
                      reverseLinkStyle
                    />
                  ) : (
                    <Fragment key={item.id}>{item.component}</Fragment>
                  )
                )}
              </MobileMenuGroup>
            ))}
          </VStack>
        )}

        {!!currentSubMenu && !isSearchScreenActive && (
          <VStack alignItems="stretch" className="mobile-menu-sub" h="100%" w="100%">
            <MobileMenuGroup>
              <MobileMenuTopPanel
                onClickBack={goPrevMenu}
                onClickSearch={() => setIsSearchScreenActive(true)}
              />
            </MobileMenuGroup>

            <MobileMenuGroup overflowX="hidden" overflowY="auto">
              <MobileMenuListItem isLink item={currentSubMenu} />

              {currentSubMenu?.options?.map((item) => (
                <MobileMenuListItem
                  item={item}
                  key={item.id}
                  onClick={() => addSubMenu(item)}
                  reverseLinkStyle
                />
              ))}
            </MobileMenuGroup>
          </VStack>
        )}

        {isSearchScreenActive ? (
          <MobileMenuSearchScreen onClose={() => setIsSearchScreenActive(false)} />
        ) : null}
      </Box>
    </HoverableComponent>
  )
}

export default SlideOutMobileMenu
