import { useMemo, useState } from 'react'
import { useSelector } from 'shared-redux'
import { Box } from '@chakra-ui/react'
import { NavBlock } from '#/src/custom/controllers/slideouts/SlideOutCategoriesDesktop/NavBlock'
import {
  Preview,
  PreviewProps
} from '#/src/custom/controllers/slideouts/SlideOutCategoriesDesktop/Preview'
import { selectedMenuCategory } from '#/src/custom/redux-toolkit/categories/customCategorySlice'
import { sortCategories } from 'shared-utils'
import { CategoryDTO } from 'ecosystem'

export const Nav = () => {
  const category = useSelector(selectedMenuCategory)

  const [selectedNestedCategory, setSelectedNestedCategory] = useState<CategoryDTO | null>(null)

  const preview = useMemo<PreviewProps>(() => {
    return {
      imgUrl: selectedNestedCategory?.imgUrl || category?.imgUrl
    }
  }, [category?.imgUrl, selectedNestedCategory?.imgUrl])

  const menuCategories = useMemo(
    () => sortCategories(category?.subCategories || []),
    [category?.subCategories]
  )

  return (
    <>
      <Box w="70%">
        <NavBlock
          categories={menuCategories}
          level={1}
          onSelectNestedCategory={setSelectedNestedCategory}
          parentCategory={category}
        />
      </Box>

      <Box w="30%">
        <Preview {...preview} />
      </Box>
    </>
  )
}
