import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'shared-redux'
import { Flex, HStack, useUpdateEffect, VStack } from '@chakra-ui/react'
import { NavListItem } from './NavListItem'
import { selectedMenuCategoryColors } from '#/src/custom/redux-toolkit/categories/customCategorySlice'
import { CategoryDTO } from 'ecosystem'

export type NavBlockProps = {
  parentCategory: CategoryDTO | null
  categories: CategoryDTO[]
  level: number
  onSelectNestedCategory?: (category: CategoryDTO | null) => void
}

const getNavBlockWidth = (level: number): number => {
  const dict: Record<string, number> = {
    '1': 1 / 3,
    '2': 1 / 2,
    '3': 1
  }

  return dict[level] || 1
}

export const NavBlock = ({
  parentCategory,
  categories,
  level,
  onSelectNestedCategory
}: NavBlockProps) => {
  const [nestedCategory, setNestedCategory] = useState<CategoryDTO | null>(null)
  const blockWidth = getNavBlockWidth(level)
  const colors = useSelector(selectedMenuCategoryColors)

  const setNestedCategoryCb = useCallback(
    (category: CategoryDTO | null) => {
      setNestedCategory(category)
      onSelectNestedCategory?.(category)
    },
    [onSelectNestedCategory]
  )

  useUpdateEffect(() => {
    setNestedCategoryCb(null)
  }, [parentCategory, categories])

  const isSubCategories = useMemo(
    () => categories.some((item) => !!item.subCategories?.length),
    [categories]
  )

  return (
    <HStack
      alignItems="stretch"
      className={`nav-block-level-${level}`}
      h="100%"
      spacing={0}
      w="100%">
      <Flex
        bg={level === 1 ? colors?.columnBg || 'gray.50' : ''}
        className={`nav-block-content-level-${level}`}
        py={6}
        w={`${blockWidth * 100}%`}>
        <VStack
          alignItems="stretch"
          borderRightColor={level > 1 ? 'text.disabled' : 'transparent'}
          borderRightWidth={level > 1 ? '1px' : 'none'}
          h="100%"
          px={6}
          spacing={1}
          w="100%">
          {parentCategory ? (
            <NavListItem
              fontWeight={level < 3 ? 'semibold' : 'normal'}
              isLink
              isReversedLinkStyle={level < 3}
              item={parentCategory}
            />
          ) : null}

          {categories.map((item) => (
            <NavListItem
              fontWeight={isSubCategories || level < 2 ? 'semibold' : 'normal'}
              isSelected={item.id === nestedCategory?.id}
              item={item}
              key={item.id}
              onClick={() => {
                setNestedCategoryCb(item)
              }}
            />
          ))}
        </VStack>
      </Flex>

      {nestedCategory && nestedCategory.subCategories?.length ? (
        <Flex w={`${(1 - blockWidth) * 100}%`}>
          <NavBlock
            categories={nestedCategory.subCategories}
            level={level + 1}
            parentCategory={nestedCategory}
          />
        </Flex>
      ) : null}
    </HStack>
  )
}
