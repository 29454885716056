'use client'
import React, { FC, useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { Box, Flex, HStack } from '@chakra-ui/react'
import { GrandNextLink, HoverableComponent } from 'ui'
import { MainMenuItem } from '#/src/custom/layout/types'
import CustomHoverableTextUnderline from '#/src/custom/components/CustomHoverableTextUnderline'

export type TopNavMenuItem<OriginalData = any> = MainMenuItem<OriginalData> & {
  isCurrent?: boolean
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  expandable?: boolean
  expanded?: boolean
  expandedHighlightBg?: string
  onHoverEnter?: () => void
  onHoverLeave?: () => void
}

const TopNavMenuItemComponent: FC<TopNavMenuItem> = ({
  onHoverEnter,
  onHoverLeave,
  text,
  path,
  isCurrent,
  onClick,
  expandable,
  expanded,
  expandedHighlightBg
}) => {
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    if (isHovered) {
      onHoverEnter?.()
    } else {
      onHoverLeave?.()
    }
  }, [isHovered, onHoverEnter, onHoverLeave])

  const expandedStyle =
    expandable && expanded
      ? {
          _after: {
            content: '""',
            position: 'absolute',
            bottom: '-13px',
            height: '13px',
            width: '100%',
            left: '0',
            backgroundColor: expandedHighlightBg || 'primary'
          }
        }
      : {}

  return (
    <Flex alignItems="center" h="100%" position="relative" userSelect="none" {...expandedStyle}>
      <GrandNextLink aria-label={text} href={path} {...{ onClick }}>
        <HoverableComponent
          borderRadius="sm"
          color="text.light"
          cursor="pointer"
          flexGrow={0}
          fontWeight={isCurrent ? 'semibold' : 'normal'}
          onHover={setIsHovered}
          w="auto">
          <CustomHoverableTextUnderline
            fontSize="sm"
            isHovered={isHovered}
            tagOfText="div"
            underlineColor="text.light">
            <HStack spacing={1}>
              <Box>{text}</Box>

              {expandable ? (
                <Box fontSize="lg">
                  <MdKeyboardArrowDown />
                </Box>
              ) : null}
            </HStack>
          </CustomHoverableTextUnderline>
        </HoverableComponent>
      </GrandNextLink>
    </Flex>
  )
}

export default TopNavMenuItemComponent
