import React, { FC, ReactElement } from 'react'
import { StackProps, VStack } from '@chakra-ui/react'
import TopNavPanel from './TopNavPanel'
import { TopNavMenuItem } from './TopNavMenuItem'
import { TopNavBarAction } from './types'
import TopNavBar from './TopNavBar'
import TopNavMenu from './TopNavMenu'
import { useCustomSearch } from '#/src/custom/search/hooks/useCustomSearch'
import { SearchPanel } from 'storefront-modules/search'
import { TopNavPanelItem } from 'ecosystem'

interface ITopNav extends StackProps {
  panelItems?: TopNavPanelItem[]
  menuItems: { categories: TopNavMenuItem[]; other: TopNavMenuItem[] }
  logo: ReactElement
  navbarActions: TopNavBarAction[]
}

const TopNav: FC<ITopNav> = ({
  panelItems,
  navbarActions,
  menuItems,
  logo,
  children,
  ...props
}) => {
  const customSearch = useCustomSearch()
  return (
    <VStack className="top-nav" position="fixed" spacing={0} w="100%" zIndex={9000} {...props}>
      <TopNavPanel panelItems={panelItems} />
      <TopNavBar
        actions={navbarActions}
        categoriesMenuItems={menuItems.categories}
        logo={logo}
        otherMenuItems={menuItems.other}
      />
      <TopNavMenu categoriesItems={menuItems.categories} otherItems={menuItems.other} />
      <SearchPanel {...customSearch} />
    </VStack>
  )
}

export default TopNav
