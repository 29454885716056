import { ReactNode } from 'react'
import { Text, VStack } from '@chakra-ui/react'
import { Link } from '@chakra-ui/next-js'
import { ResponsiveImage } from 'ui'

export type PreviewProps = {
  imgUrl?: string
  title?: string
  description?: ReactNode
  link?: {
    label: string
    href: string
  }
}

export const Preview = ({ imgUrl, title, description, link }: PreviewProps) => {
  if (!imgUrl && !title && !description && !link) {
    return null
  }

  return (
    <VStack alignItems="stretch" pr={6} py={6} spacing={4}>
      {!!imgUrl && <ResponsiveImage alt={title || ''} borderRadius="xl" h="250px" src={imgUrl} />}

      {(!!link || !!title || !!description) && (
        <VStack alignItems="stretch" fontSize="sm" spacing={4}>
          {(!!title || !!description) && (
            <VStack alignItems="stretch" spacing={1}>
              {!!title && <Text fontWeight="semibold">{title}</Text>}

              {!!description && <Text>{description}</Text>}
            </VStack>
          )}

          {!!link && (
            <Link
              _hover={{
                textDecoration: 'none'
              }}
              color="primary"
              fontWeight="semibold"
              href={(link as PreviewProps['link'])?.href || ''}
              textDecoration="underline">
              {(link as PreviewProps['link'])?.label}
            </Link>
          )}
        </VStack>
      )}
    </VStack>
  )
}
