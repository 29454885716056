import { BiSearch } from 'react-icons/bi'
import { GrandIconButton, MAX_CONTENT_WIDTH } from 'ui'
import { CustomSlideOutPayloadProps, useSlideOut } from '#/src/custom/controllers'
import { useCallback } from 'react'

// is used for desktop view to open SearchDesktopSlideOut
export const CustomSearchButton = () => {
  const { isHidden, closeSlideOut, openSlideOut, componentId, overrideComponent } = useSlideOut()

  const handleClick = useCallback(() => {
    const slideOutProps: CustomSlideOutPayloadProps<void> = {
      title: 'Search',
      componentId: 'searchDesktop' as const,
      shouldUnmountComponentAfterClose: true,
      drawerProps: {
        sx: {
          bg: 'transparent',
          px: '0',
          boxShadow: 'none',
          '.slide-out__content-container': {
            py: '0',
            maxWidth: MAX_CONTENT_WIDTH,
            margin: 'auto',
            bg: 'none'
          },
          '.slide-out__content-body': {
            px: '0'
          }
        }
      }
    }

    if (!isHidden && componentId !== 'searchDesktop') {
      overrideComponent(slideOutProps)
    } else if (isHidden) {
      openSlideOut(slideOutProps)
    } else {
      closeSlideOut()
    }
  }, [closeSlideOut, componentId, isHidden, openSlideOut, overrideComponent])

  return (
    <GrandIconButton
      alignItems="center"
      aria-label="search"
      bg="none"
      color="text.light"
      fontSize="2xl"
      icon={<BiSearch />}
      justifyContent="center"
      onClick={handleClick}
      shadow="none"
      size="sm"
    />
  )
}
