import { FC } from 'react'
import CustomContact, { CustomContactProps } from '#/src/custom/components/CustomContact'
import { OverridesContextProvider, OverridesContextType } from 'ui/lib/overrides'
import { ContactUsFormOverrides } from 'forms'

export interface ModalContactPriceRequestProps extends CustomContactProps {}

const textOverrides: ContactUsFormOverrides = {
  contactUsFormName: 'För- och efternamn',
  contactUsFormNamePlaceholder: 'Fyll i ditt för- och efternamn',
  contactUsFormPhonePlaceholder: 'Fyll i ditt telefonnummer',
  contactUsFormEmailPlaceholder: 'Fyll i din e-postadress',
  contactUsFormProductPlaceholder: 'Fyll i produktnamn',
  contactUsFormDescriptionPlaceholder: 'Lägg till en beskrivning av din förfrågan (frivilligt)',
  contactUsFormOrderNumberPlaceholder: 'Fyll i ordernummer (frivilligt)'
}

const ModalContactPriceRequest: FC<ModalContactPriceRequestProps> = ({ ...props }) => {
  return (
    <OverridesContextProvider overrides={textOverrides as OverridesContextType}>
      <CustomContact {...props} />
    </OverridesContextProvider>
  )
}

export default ModalContactPriceRequest
