import { FC } from 'react'

import { Nav } from './Nav'
import { ContentContainer } from 'ui'

export interface ISlideOutProducts {}

const SlideOutCategories: FC<ISlideOutProducts> = () => {
  return (
    <ContentContainer
      className="slide-out-categories"
      minH="20vh"
      p="0"
      py={{
        base: 4,
        md: 0
      }}
      spacing="6"
      w="100%">
      <Nav />
    </ContentContainer>
  )
}

export default SlideOutCategories
