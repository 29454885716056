'use client'
import { storefrontBaseStoreCreator } from 'shared-redux/state/store'
import { cookieSlice, CookieState } from 'cookies'
import { Reducer } from 'shared-redux'
import { ProductsState } from 'shared-redux/state'
import customCategorySlice from './categories/customCategorySlice'
import { CustomCategoryState } from './categories/types'
import customProductsSlice from './products'
import { bokaServiceReducer } from '#/src/custom/widgets/CustomWidgetId/BokaService/slice'
import { BokaServiceState } from '#/src/custom/widgets/CustomWidgetId/BokaService/types'

// Add the extensions paths and states to these types
type StoreStatePlugins =
  | Reducer<CustomCategoryState>
  | Reducer<CookieState>
  | Reducer<ProductsState>
  | Reducer<BokaServiceState>
type StoreReducerPaths = 'category' | 'cookies' | 'products' | 'bokaService'

export const storeConfig = storefrontBaseStoreCreator<StoreStatePlugins, StoreReducerPaths>([
  // The store slices can be extended here.
  { reducerPath: 'cookies', reducer: cookieSlice },
  { reducerPath: 'bokaService', reducer: bokaServiceReducer },
  { reducerPath: 'category', reducer: customCategorySlice },
  { reducerPath: 'products', reducer: customProductsSlice }
])

export type CustomRootState = ReturnType<typeof storeConfig.getState>
