import React, { FC, useMemo } from 'react'
import { useSelector } from 'shared-redux'
import { Center, Flex, FlexProps, HStack } from '@chakra-ui/react'
import appConfig from '#/src/appConfig'
import { ContentContainer, GrandNextLink, GrandText, UIIcons } from 'ui'
import { selectIsWindowOnTop } from 'shared-redux/state'
import type { TopNavPanelItem } from 'ecosystem'

const PanelItem = ({ item: { icon, href, label } }: { item: TopNavPanelItem }) => {
  const Icon = useMemo(() => (icon && UIIcons[icon]) || (() => null), [icon])

  return (
    <Center flex={1}>
      <HStack
        {...(href
          ? {
              as: GrandNextLink,
              'aria-label': label,
              href
            }
          : {})}>
        {!!icon && (
          <Flex fontSize="xs">
            <Icon />
          </Flex>
        )}

        <GrandText fontSize="xs" isTruncated w="auto">
          {label}
        </GrandText>
      </HStack>
    </Center>
  )
}

interface IPanel extends FlexProps {
  panelItems?: TopNavPanelItem[]
}

const TopNavPanel: FC<IPanel> = ({ panelItems, ...props }) => {
  const isWindowOnTop = useSelector(selectIsWindowOnTop)

  if (!panelItems?.length) {
    return null
  }

  return (
    <Flex
      bg="text.default"
      className="top-nav-panel"
      fontSize="xs"
      h={isWindowOnTop ? appConfig.topNavHeight.panel : 0}
      letterSpacing={0.2}
      opacity={isWindowOnTop ? 1 : 0}
      px={{
        base: 2,
        md: 10
      }}
      transition="all 0.25s ease"
      w="100%"
      {...props}>
      <ContentContainer color="text.light" p={0}>
        {panelItems.map((item, index) => (
          <PanelItem item={item} key={`${item.label}${index}`} />
        ))}
      </ContentContainer>
    </Flex>
  )
}

export default TopNavPanel
