import { RefObject, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { BiSearch } from 'react-icons/bi'
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Spinner
} from '@chakra-ui/react'
import { GrandIconButton } from 'ui'

type MobileMenuSearchPanelProps = {
  inputRef: RefObject<HTMLInputElement>
  inputProps?: InputProps
  onClickClose: () => void
  isLoading?: boolean
}

export const MobileMenuSearchPanel = ({
  onClickClose,
  isLoading,
  inputRef,
  inputProps
}: MobileMenuSearchPanelProps) => {
  useEffect(() => {
    inputRef.current?.focus?.()
  }, [inputRef])

  return (
    <Flex
      alignItems="center"
      className="mobile-menu-search-panel"
      justifyContent="space-between"
      w="100%">
      <Box className="mobile-menu-search-panel__input-container" flex="1" position="relative">
        <Box
          fontSize="xl"
          left={4}
          pointerEvents="none"
          position="absolute"
          top="50%"
          transform="translateY(-50%)">
          <BiSearch />
        </Box>

        <InputGroup>
          <Input
            _focus={{
              border: 'none'
            }}
            _focusVisible={{
              border: 'none'
            }}
            _placeholder={{
              fontSize: 'xs'
            }}
            border="none"
            h="auto"
            pl={12}
            placeholder="Sök efter produkt eller tjänst"
            py={4}
            ref={inputRef}
            {...inputProps}
          />

          {isLoading ? (
            <InputRightElement h="100%" top={0}>
              <Spinner color="text.default" opacity="0.7" size="xs" />
            </InputRightElement>
          ) : null}
        </InputGroup>
      </Box>

      <GrandIconButton
        aria-label="Close search"
        icon={<MdClose />}
        onClick={onClickClose}
        px={6}
        py={4}
        shadow="none"
        variant="ghost"
      />
    </Flex>
  )
}
