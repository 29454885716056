import React from 'react'
import { Link } from '@chakra-ui/next-js'
import { GrandText } from 'ui'

export type FooterBottomItemProps = {
  text: string
  path?: string
}

const commonStyle = {
  color: 'text.lightMild',
  fontSize: 'sm'
}

export const FooterBottomItem = ({ text, path }: FooterBottomItemProps) => {
  if (path) {
    return (
      <Link _focus={{ outline: 'none' }} aria-label={text} href={path} {...commonStyle}>
        {text}
      </Link>
    )
  }

  return (
    <GrandText as="span" width="unset" {...commonStyle}>
      {text}
    </GrandText>
  )
}
