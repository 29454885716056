import { createSlice, PayloadAction } from 'shared-redux'
import { CustomCategoryState, MenuCategoryColors } from './types'
import { CustomRootState } from '#/src/custom/redux-toolkit/store.config'

const initialState: CustomCategoryState = {
  menuCategory: null,
  navigateToCategoryId: null,
  colors: null
}

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setNavigateToCategoryId: (state, { payload }: PayloadAction<{ categoryId: string | null }>) => {
      state.navigateToCategoryId = payload.categoryId
    },

    setSelectedMenuCategory: (
      state,
      { payload }: PayloadAction<CustomCategoryState['menuCategory']>
    ) => {
      state.menuCategory = payload
    },

    setSelectedMenuCategoryColors: (
      state,
      { payload }: PayloadAction<MenuCategoryColors | null>
    ) => {
      state.colors = payload
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { setNavigateToCategoryId, setSelectedMenuCategory, setSelectedMenuCategoryColors } =
  slice.actions

// available selectors - add more as needed
const selectCategory = (state: CustomRootState) => state.category as CustomCategoryState
export const selectedMenuCategory = (state: CustomRootState) => selectCategory(state).menuCategory
export const selectedMenuCategoryId = (state: CustomRootState) =>
  selectedMenuCategory(state)?.id ?? null
export const selectedMenuCategoryColors = (state: CustomRootState) => selectCategory(state).colors
export const selectedNavigateToCategory = (state: CustomRootState) =>
  selectCategory(state).navigateToCategoryId

export default slice.reducer
