import { VStack } from '@chakra-ui/react'
import { MobileMenuGroup } from './MobileMenuGroup'
import { MobileMenuSearchPanel } from './/MobileMenuSearchPanel'
import { useCustomSearch } from '#/src/custom/search/hooks/useCustomSearch'
import { SearchResults } from 'storefront-modules/search'

type MobileMenuSearchScreenProps = {
  onClose: () => void
}

export const MobileMenuSearchScreen = ({ onClose }: MobileMenuSearchScreenProps) => {
  const { data, onKeyDown, inputRef, searchQuery, isLoading } = useCustomSearch()

  return (
    <VStack alignItems="stretch" className="mobile-menu-search" h="100%" w="100%">
      <MobileMenuGroup>
        <MobileMenuSearchPanel
          inputProps={{
            onKeyDown
          }}
          inputRef={inputRef}
          isLoading={isLoading}
          onClickClose={onClose}
        />
      </MobileMenuGroup>

      <MobileMenuGroup overflowX="hidden" overflowY="auto">
        <SearchResults
          items={data}
          maxLength={10}
          searchPagePath="/search"
          searchQuery={searchQuery}
          w="100%"
        />
      </MobileMenuGroup>
    </VStack>
  )
}
