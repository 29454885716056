import { FC, PropsWithChildren } from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { Button, HStack } from '@chakra-ui/react'
import { customTheme } from '#/src/custom/styles/theme'

type ModalPhotoGalleryControlsProps = PropsWithChildren<{
  onNextClick: () => void
  onPrevClick: () => void
}>

const ModalPhotoGalleryControls: FC<ModalPhotoGalleryControlsProps> = ({
  onNextClick,
  onPrevClick,
  children
}) => {
  return (
    <HStack>
      <Button aria-label="left" color="text.default" minW={0} onClick={onPrevClick} variant="link">
        <BiChevronLeft color={customTheme.colors.text.default} size={30} />
      </Button>

      {children}

      <Button
        aria-label="right"
        color="text.default"
        minW={0}
        onClick={onNextClick}
        variant="link"
        zIndex={2}>
        <BiChevronRight color={customTheme.colors.text.default} size={30} />
      </Button>
    </HStack>
  )
}

export default ModalPhotoGalleryControls
