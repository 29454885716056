import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import SlideOutCloseTitle from './SlideOutCloseTitle'
import type { FC, MouseEvent } from 'react'
import { BiChevronRight } from 'react-icons/bi'
import { useDispatch } from 'shared-redux'
import { CategoryDTO } from 'ecosystem'
import { GrandNextLink } from 'ui'
import appConfig from '#/src/appConfig'
import { sortCategories } from 'shared-utils'
import { setNavigateToCategoryId } from '../../redux-toolkit/categories/customCategorySlice'

export interface ISlideOutMoreCategoriesProps {
  category: CategoryDTO
}

const SlideOutMoreCategories: FC<ISlideOutMoreCategoriesProps> = ({ category }) => {
  const dispatch = useDispatch()

  const handleItemClick = async (e: MouseEvent, item: CategoryDTO) => {
    e.preventDefault()
    dispatch(setNavigateToCategoryId({ categoryId: item.slug }))
  }

  return (
    <VStack spacing={10} w="100%">
      <SlideOutCloseTitle title={`${category.name}`} />
      <VStack align="stretch" spacing={2} w="100%">
        {sortCategories(category.subCategories || []).map((subcategory) => (
          <HStack cursor="pointer" key={subcategory.id} p={1}>
            <GrandNextLink
              href={`${appConfig.paths.categoriesUrlPath}/${subcategory.slug}`}
              onClick={(e) => handleItemClick(e, subcategory)}>
              <Text flexGrow={1} isTruncated>
                {subcategory.name}
              </Text>
            </GrandNextLink>

            <Box>
              <BiChevronRight />
            </Box>
          </HStack>
        ))}
      </VStack>
    </VStack>
  )
}
export default SlideOutMoreCategories
